// Images
import banana_caturra_01 from '../../assets/images/banana-caturra-01.jpg';
import banana_caturra_02 from '../../assets/images/banana-caturra-02.jpg';
import banana_caturra_03 from '../../assets/images/banana-caturra-03.jpg';
import banana_prata_01 from '../../assets/images/banana-prata-01.jpg';
import banana_prata_02 from '../../assets/images/banana-prata-02.jpg';
import banana_prata_03 from '../../assets/images/banana-prata-03.jpg';

export const produtos_images = [
    {
        "bananaType": "caturra",
        "bananaImages": [
            {
                id: 1,
                url: banana_caturra_01
            },
            {
                id: 2,
                url: banana_caturra_02
            },
            {
                id: 3,
                url: banana_caturra_03
            }
        ]
    },
    {
        "bananaType": "prata",
        "bananaImages": [
            {
                id: 1,
                url: banana_prata_01
            },
            {
                id: 2,
                url: banana_prata_02
            },
            {
                id: 3,
                url: banana_prata_03
            }
        ]
    }
];

export default produtos_images;