import React from 'react';
import './Beneficios.css';
import { Helmet } from 'react-helmet-async';

// Components
import MainBanner from '../../components/MainBanner/MainBanner';
import Spotlight from '../../components/Spotlight/Spotlight';

// Images
import banner_background_beneficios from '../../assets/images/banner-background-beneficios.jpg';
import icon_meditation from '../../assets/images/icon-meditation.png';
import icon_banana_02 from '../../assets/images/icon-banana-02.png';

// Data
import beneficios_data from './beneficios_data';

const Beneficios = () => {

    return (

        <div>

            <Helmet>
                <title>Frutyl | Benefícios</title>
            </Helmet>

            <MainBanner
                page='beneficios' 
                pageMainTitle='Vida saudável e longeva'
                pageSubtitle={`Conheça alguns benefícios que a banana pode lhe oferecer.${'\n'}Confira os nutrientes saudáveis que a banana pode fornecer à sua saúde.`}
                backgroundImage={banner_background_beneficios}
                backgroundPosition={[{mobile: 'center center', desktop: 'center center'}]}
                additionalInformation={null}
            />

            <div className='main-container'>

                <div className='main-title'>
                    <h1>BENEFÍCIOS DA BANANA</h1>
                </div>

                <div className='beneficios-content'>

                    <div className='beneficios-summary'>
                        <p>
                            A banana é uma das frutas mais consumidas pelos brasileiros. Além de ser extremamente acessível e versátil, a banana traz diversos benefícios para a nossa saúde.
                        </p>
                        <p>
                            Listamos alguns dos principais benefícios que essa fruta proporciona ao nosso corpo e à nossa saúde mental.
                        </p>
                        <div className='beneficios-summary-image'>
                            <img src={icon_meditation} alt='' />
                        </div>
                    </div>

                    {
                        beneficios_data.map((beneficio) => {
                            return (
                                <div key={beneficio.beneficioId} className='beneficio-banana'>
                                    <div className='beneficio-title'>
                                        <h3>BENEFÍCIO #{beneficio.beneficioId}</h3>
                                        <h2>{beneficio.beneficioTitle}</h2>
                                    </div>
                                    {
                                        beneficio.beneficioContent.map(content => {
                                            return (
                                                <p key={content.contentId}>{content.contentText}</p>
                                            )
                                        })
                                    }
                                </div>
                            )
                        })
                    }

                    <div className='beneficios-footer-image'>
                        <img src={icon_banana_02} alt='' />
                    </div>

                </div>
                
            </div>

            <Spotlight
                spotlightLink='/receitas'
                spotlightTitle='RECEITAS'
                spotlightDescription={`A banana é uma fruta que faz muito bem à nossa saúde.${`\n`}Ficou com vontade de preparar algumas receitas?${`\n\n`}Clique e delicie-se.`}
                spotlightButton='RECEITAS CULINÁRIAS'
            />

        </div>
    );
}

export default Beneficios;