import React, { useEffect, useState } from 'react';
import './Contato.css';
import { Helmet } from 'react-helmet-async';

// Components
import MainBanner from '../../components/MainBanner/MainBanner';
import ContatoForm from '../../components/ContatoForm/ContatoForm';
import Spotlight from '../../components/Spotlight/Spotlight';

// Images
import banner_background_contato from '../../assets/images/banner-background-contato.jpg';
import icon_paper_plane from '../../assets/images/icon-paper-plane.png';
import icon_instagram from '../../assets/images/icon-instagram.png';
import icon_facebook from '../../assets/images/icon-facebook.png';
import email_successful from '../../assets/images/icon-successful.png';
import email_unsuccessful from '../../assets/images/icon-unsuccessful.png';

const Contato = () => {

    const [formOutcome, setFormOutcome] = useState('');
    const [isFormNotificationDisplayed, setIsFormNotificationDisplayed] = useState(false);

    useEffect(() => {
        if (isFormNotificationDisplayed) {
            document.getElementById('form-notification').classList.add('display-form-notification');
        } else {
            document.getElementById('form-notification').classList.remove('display-form-notification');
        }
    }, [isFormNotificationDisplayed]);


    useEffect(() => {
        if (formOutcome === true || formOutcome === false) {
            setIsFormNotificationDisplayed(true);
        }
    }, [formOutcome]);

    return (

        <div>

            <Helmet>
                <title>Frutyl | Contato</title>
            </Helmet>

            <div className='form-notification' id='form-notification'>
                <img src={formOutcome === true ? email_successful : 
                          formOutcome === false ? email_unsuccessful :
                          ''} alt='' />
                <h2>
                    {
                        formOutcome === true ? 'Seu e-mail foi enviado com sucesso' : 
                        formOutcome === false ? 'Seu e-mail não pôde ser enviado' : 
                        ''
                    }
                </h2>
                <p>
                    {
                        formOutcome === true ? 'Entraremos em contato com você assim que possível.' : 
                        formOutcome === false ? 'Sem problemas! Disponibilizamos outras opções nesta página para você entrar em contato conosco.' :
                        ''
                    }
                </p>
                <button onClick={() => setIsFormNotificationDisplayed(false)}>Clique para fechar</button>
            </div>

            <MainBanner
                page='contato'
                pageMainTitle='Estamos à sua disposição'
                pageSubtitle='Entre em contato sempre quando achares necessário!'
                backgroundImage={banner_background_contato}
                backgroundPosition={[{mobile: 'bottom left', desktop: 'left 55%'}]}
                additionalInformation={null}
            />

            <div className='main-container'>

                <div className='main-title'>
                    <h1>FALE CONOSCO</h1>
                </div>

                <div className='contato-content'>
                    <div className='contato-summary'>
                        <p>
                            Utilize o nosso formulário para entrar em contato com a Frutyl.
                        </p>
                        <p>
                            Entraremos em contato com você assim que possível.
                        </p>
                        <div className='contato-summary-image'>
                            <img src={icon_paper_plane} alt='' />
                        </div>
                    </div>
                    <div className='contato-form-wrapper'>
                        <ContatoForm setFormOutcome={setFormOutcome} />
                    </div>
                    <div className='contato-additional-info'>
                        <div className='additional-info-summary'>
                            <p>Há outras maneiras de você entrar em contato com a Frutyl.</p>
                        </div>
                        <div className='additional-info-email'>
                            <h3>E-mail</h3>
                            <p>frutyl.cb@gmail.com</p>
                        </div>
                        <div className='additional-info-phone'>
                            <h3>Telefone & WhatsApp</h3>
                            <p>(51) 3488-3161</p>
                        </div>
                        <div className='additional-info-address'>
                            <h3>Endereço</h3>
                            <p>Rua Augusto Rocha, 80. Bairro São Geraldo — Gravataí, Rio Grande do Sul.</p>
                        </div>
                        <div className='additional-info-socials'>
                            <p>A Frutyl está nas redes sociais</p>
                            <div className='socials-links'>
                                <a target='_blank' rel='noopener noreferrer' href='https://instagram.com/frutyl_'>
                                    <img src={icon_instagram} alt='Instagram da Frutyl' />
                                </a>
                                <a target='_blank' rel='noopener noreferrer' href='https://facebook.com/Frutyl'>
                                    <img src={icon_facebook} alt='Facebook da Frutyl' />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Spotlight
                spotlightLink='/sobre'
                spotlightTitle='IDEOLOGIA'
                spotlightDescription={`Gostaria de conhecer um pouco mais sobre a nossa empresa?${`\n`}Disponibilizamos uma descrição sobre a nossa história e os nossos valores de negócio.${`\n\n`}Venha saber mais.`}
                spotlightButton='CONHECER A FRUTYL'
            />

        </div>
    );
}

export default Contato;