// Images
import receita_panquecaDeBanana from '../../assets/images/receita-panqueca-de-banana.jpg';
import receita_boloDeBanana from '../../assets/images/receita-bolo-de-banana.jpg';
import receita_smoothieDeBanana from '../../assets/images/receita-smoothie-de-banana.jpg';
import receita_tortaBanoffee from '../../assets/images/receita-torta-banoffee.jpg';
import receita_cookieDeBanana from '../../assets/images/receita-cookie-de-banana.jpg';
import receita_muffinDeBanana from '../../assets/images/receita-muffin-de-banana.jpg';
import receita_tostexDeBanana from '../../assets/images/receita-tostex-de-banana.jpg';
import receita_moquecaDeBanana from '../../assets/images/receita-moqueca-de-banana.jpg';

export const receitas_data = [
    {
        "receitaId": "1",
        "receitaShortName": "Panqueca de banana",
        "receitaFullName": "Panqueca de banana",
        "receitaIngredients": [
            "2 bananas caturras", 
            "2 ovos", 
            "200 ml de leite",
            "3 colheres de sopa de açúcar",
            "1 xícara e meia de farinha de trigo",
            "Óleo para untar",
            "Canela em pó a gosto",
            "150 ml de mel (opcional)"
        ],
        "receitaCooking": [
            "Coloque os seguintes ingredientes no liquidificador: bananas, leite, farinha, ovos e açúcar",
            "Bata os ingredientes no liquidificador",
            "Coloque um pouco de óleo em uma frigideira",
            "Leve a frigideira ao seu fogão e a coloque em um fogo médio",
            "Aguarde até que a frigideira fique quente e coloque 1/2 concha média no recipiente",
            "Espalhe o conteúdo para formar uma massa fina",
            "Após um período, vire a panqueca na frigideira",
            "Quando os dois lados estiverem dourados, retire a panqueca da frigideira",
            "Coloque a panqueca em uma travessa e despeje um pouco do mel e da canela em pó por cima",
            "Aproveite! :)"
        ],
        "receitaTime": "30 minutos",
        "receitaImage": receita_panquecaDeBanana
    },
    {
        "receitaId": "2",
        "receitaShortName": "Bolo de banana", 
        "receitaFullName": "Bolo de banana com aveia",
        "receitaIngredients": [
            "4 bananas maduras",
            "4 ovos",
            "1/2 xícara de azeite",
            "2 xícaras de flocos finos de aveia",
            "2 colheres (de sopa) de fermento em pó",
            "1 colher (de chá) de canela em pó (opcional)"
        ],
        "receitaCooking": [
            "Corte as bananas e coloque-as no liquidificador",
            "Acrescente os ovos e o azeite",
            "Bata os ingredientes no liquidificador",
            "Coloque a mistura em uma tigela e adicione os flocos de aveia e a canela (se foi optado)",
            "Misture bem os ingredientes",
            "Adicione o fermento e misture sem bater muito",
            "Despeje a massa em uma forma de silicone ou em uma forma untada",
            "Leve ao forno por cerca de 35 minutos à 180ºC",
            "Aproveite! :)"
        ],
        "receitaTime": "60 minutos",
        "receitaImage": receita_boloDeBanana
    },
    {
        "receitaId": "3",
        "receitaShortName": "Smoothie de banana",
        "receitaFullName": "Smoothie de banana",
        "receitaIngredients": [
            "2 bananas congeladas",
            "5 cubos de gelo",
            "200 ml de água",
            "Suco de 1/2 limão",
            "Canela a gosto",
            "Hortelã a gosto",
            "Açúcar a gosto"
        ],
        "receitaCooking": [
            "Corte as bananas congeladas",
            "Coloque todos os ingredientes no liquidificador",
            "Bata os ingredientes no liquidificador",
            "Retire o líquido do liquidificador e os despeje em um copo",
            "Salpique um pouco de canela por cima",
            "Aproveite! :)"
        ],
        "receitaTime": "5 minutos",
        "receitaImage": receita_smoothieDeBanana
    },
    {
        "receitaId": "4",
        "receitaShortName": "Torta banoffee",
        "receitaFullName": "Torta banoffee",
        "receitaIngredients": [
            "10 bananas",
            "400g de nata ou chantilly",
            "400g de doce de leite pronto",
            "200g de margarina",
            "1 pacote de biscoito maizena",
            "1 colher de canela em pó para polvilhar"
        ],
        "receitaCooking": [
            "Corte as bananas em rodelas",
            "Triture o biscoito maizena até ficar bem fininho",
            "Misture o biscoito triturado com a margarina até formar uma massa lisa",
            "Ligue o forno a uma temperatura de 180ºC",
            "Leve a massa para assar por 10 minutos",
            "Após esse tempo, retire a massa do forno e deixe-a esfriar",
            "Despeje e alise o doce de leite sobre a massa",
            "Coloque as bananas cortadas por cima do doce de leite",
            "Despeje e alise a nata/chantilly por cima das bananas",
            "Salpique com canela",
            "Leve a torta à geladeira e espere 3 horas para desenformar",
            "Aproveite! :)"
        ],
        "receitaTime": "30 minutos",
        "receitaImage": receita_tortaBanoffee
    },
    {
        "receitaId": "5",
        "receitaShortName": "Cookie de banana",
        "receitaFullName": "Cookie de banana com aveia",
        "receitaIngredients": [
            "1 xícara (de chá) de banana amassada",
            "1 xícara (de chá) de aveia em flocos finos",
            "1 colher (de sopa) de óleo vegetal",
            "1 colher (de chá) de fermento químico em pó",
            "Canela em pó a gosto (opcional)",
            "1/4 de xícara (de chá) de uvas passas (opcional)",
            "1/4 de xícara (de chá) de castanha do Pará picadas (opcional)"
        ],
        "receitaCooking": [
            "Pré-aqueça o forno a 180ºC",
            "Coloque os seguintes ingredientes em uma vasilha: banana amassada, aveia em flocos finos, óleo vegetal, fermento e canela",
            "Misture os ingredientes na vasilha",
            "(OPCIONAL) Adicione as uvas passas e as castanhas picadas",
            "Misture bem, até ficar uniforme",
            "Coloque essa massa sobre uma assadeira forrada com papel manteiga",
            "Leve para assar por cerca de 20 minutos",
            "Após estar bem sequinho, remova o papel com cuidado",
            "Aproveite! :)"
        ],
        "receitaTime": "25 minutos",
        "receitaImage": receita_cookieDeBanana
    },
    {
        "receitaId": "6",
        "receitaShortName": "Muffin de banana",
        "receitaFullName": "Muffin de banana com amendoim",
        "receitaIngredients": [
            "2 bananas maduras",
            "3/4 xícara de pasta de amendoim com coco queimado",
            "2 colheres (de sopa) de óleo de sua preferência",
            "1/3 xícara de mel",
            "1 colher (de sopa) de suco de laranja",
            "3 ovos batidos",
            "1 colher (de chá) de canela",
            "1/8 colher (de chá) de noz-moscada",
            "1 colher (de chá) de bicarbonato de sódio ",
            "1/4 xícara de aveia em flocos - para massa",
            "2 colheres (de sopa) de aveia em flocos - para finalização",
            "3 colheres (de sopa) de amendoim - para finalização"
        ],
        "receitaCooking": [
            "Pré-aqueça o forno a 180ºC",
            "Separe algumas forminhas de silicone",
            "Com exceção do bicarbonato de sódio, bata todos os ingredientes da massa em um liquidificador",
            "Misture o bicarbonato de sódio e a aveia",
            "Coloque nas forminhas e salpique um pouco de aveia em flocos por cima",
            "Leve para assar por cerca de 20 minutos",
            "Retire do forno e deixe esfriar um pouco",
            "Aproveite! :)"
        ],
        "receitaTime": "35 minutos",
        "receitaImage": receita_muffinDeBanana
    },
    {
        "receitaId": "7",
        "receitaShortName": "Tostex de banana",
        "receitaFullName": "Tostex de banana com creme de avelã",
        "receitaIngredients": [
            "2 fatias de pão de trigo integral (adicione mais 2 fatias se quiser fazer mais porções)",
            "1 banana",
            "2 colheres (de sopa) de pasta de avelã e cacau"
        ],
        "receitaCooking": [
            "Corte a banana em rodelas finas",
            "Pegue as duas fatias de pão e espalhe 1 colher (de sopa) de creme de avelã e cacau",
            "Disponha as rodelas de banana em uma das fatias",
            "Sobrepõe as fatias e feche o sanduíche",
            "Coloque o sanduíche no tostex e leve ao fogo médio",
            "Após cerca de 1 minuto e 30 segundos, vire o sanduíche no tostex",
            "Retire o sanduíche do tostex após ambos os lados estarem crocantes e levemente dourados",
            "Aproveite! :)"
        ],
        "receitaTime": "15 minutos",
        "receitaImage": receita_tostexDeBanana
    },
    {
        "receitaId": "8",
        "receitaShortName": "Moqueca de banana",
        "receitaFullName": "Moqueca de banana",
        "receitaIngredients": [
            "3 bananas maduras",
            "2 tomates maduros",
            "1 pimentão verde",
            "1 cebola",
            "3 dentes de alho",
            "3/4 de xícara (de chá) de leite de coco (200 ml)",
            "2 xícaras (de chá) de água",
            "1/4 de xícara (de chá) de azeite de dendê",
            "2 colheres (de sopa) de azeite",
            "10 ramos de coentro",
            "2 raízes de coentro",
            "2 pimentas dedo-de-moça",
            "2 pimentas-de-cheiro",
            "1 tira de casca de limão taiti",
            "Sal a gosto"
        ],
        "receitaCooking": [
            "Lave e seque os seguintes ingredientes: pimentões, tomates, pimentas e coentro (mantenha as raízes e talos)",
            "Corte os pimentões e o tomate em cubos, descartando as sementes",
            "Descasque e corte a cebola em cubos",
            "Descasque e pique fino os dentes de alho",
            "Pique fino as raízes e os talos do coentro e reserve as folhas para a finalização",
            "Corte as pimentas dedo-de-moça e cheiro ao meio, descartando as sementes",
            "Pique fino as metades",
            "Leve uma panela média ao fogo médio para aquecer",
            "Regue com 1 colher (de sopa) de azeite, adicione os seguintes ingredientes: pimentas, alho, raízes e talos do coentro",
            "Tempere com uma pitada de sal e refogue por cerca de 3 minutos",
            "Acrescente água e casca de limão e misture bem",
            "Pegue uma concha e transfira o caldo para o liquidificador",
            "Adicione o azeite de dendê, leite de coco e sal e bata por cerca de 2 minutos até ficar emulsionado",
            "Coloque a panela ao fogo médio novamente e regue com 1 colher (de sopa) de azeite e adicione os seguintes ingredientes: pimentões, cebola e uma pitada de sal",
            "Refogue por cerca de 4 minutos",
            "Descasque as bananas e as corte em formato de rodelas",
            "Acrescente as bananas e os tomates ao refogado e misture delicadamente",
            "Tampe a panela, abaixe o fogo e deixe cozinhar por cerca de 15 minutos",
            "Finalize com as folhas de coentro",
            "Aproveite! :)"
        ],
        "receitaTime": "120 minutos",
        "receitaImage": receita_moquecaDeBanana
    }
];

export default receitas_data;