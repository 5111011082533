import React, { useEffect, useState } from 'react';
import './Servicos.css';
import { Helmet } from 'react-helmet-async';

// Components
import MainBanner from '../../components/MainBanner/MainBanner';
import Map from '../../components/Map/Map';
import Spotlight from '../../components/Spotlight/Spotlight';

// Images
import banner_background_servicos from '../../assets/images/banner-background-servicos.jpg';
import frutyl_current from '../../assets/images/frutyl-current.jpg';
import frutyl_awards  from '../../assets/images/frutyl-awards.jpg';
import icon_basket_fruit from '../../assets/images/icon-basket-fruit.png';
import icon_together from '../../assets/images/icon-together.png';
import icon_plus from '../../assets/images/icon-plus.png';
import icon_minus from '../../assets/images/icon-minus.png';

// Data
import feiras_data from './feiras_data';
import parceiros_data from './parceiros_data';

// console.log(feiras_data);
// console.log(parceiros_data)

const Servicos = ({ windowWidth }) => {

    const [isFeirasDisplayed, setIsFeirasDisplayed] = useState(false);
    const [feira, setFeira] = useState('');
    const [isParceirosDisplayed, setIsParceirosDisplayed] = useState(false);
    
    const displayFeiras = () => setIsFeirasDisplayed(true);

    useEffect(() => {
        if (isFeirasDisplayed) {
            document.getElementById('feiras').classList.add('display-feiras');
        } else {
            document.getElementById('feiras').classList.remove('display-feiras');
        }
    }, [isFeirasDisplayed]);

    const feirasHandler = (event, feiraId) => {
        const scrollToFeiras = document.getElementById('feiras');
        const button = event.target;
        handleFeirasButtons(button, feiraId);
        handleFeirasContent(feiraId);
        setTimeout(() => {
            handleScroll(scrollToFeiras);
        }, 100);
    }

    const handleFeirasButtons = (button, feiraId) => {
        console.log(button);
        const buttons = document.querySelectorAll('.feiras-button');
        console.log(buttons);
        buttons.forEach(button => {
            if (feiraId === button.id) {
                button.classList.add('feiras-button-active');
            } else {
                button.classList.remove('feiras-button-active');
            }
        })
    }

    const handleFeirasContent = (feiraId) => {
        if (!isFeirasDisplayed) {
            displayFeiras();
        }
        feiras_data.find((feira) => {
            if (feira.feiraId === feiraId) {
                console.log(feira);
                setFeira(feira);
            }
        })
    }

    const handleCloseFeiras = () => {
        setIsFeirasDisplayed(false);
        const scrollToFeirasContainer = document.getElementById('feiras-container');
        const buttons = document.querySelectorAll('.feiras-button');
        buttons.forEach(button => button.classList.remove('feiras-button-active'));
        handleScroll(scrollToFeirasContainer);
    }

    const handleScroll = (element) => {
        element.scrollIntoView({behavior: 'smooth'})
    }

    useEffect(() => {
        const parceirosButton = document.getElementById('parceiros-button');
        const parceirosContent = document.getElementById('parceiros-content');
        const parceirosImage = document.getElementsByClassName('parceiros-image-wrapper');
        const parceirosImageArray = Array.from(parceirosImage);
        if (isParceirosDisplayed) {
            parceirosButton.innerHTML = `<img src='${icon_minus}' alt='' />`;
            parceirosContent.classList.add('display-parceiros-content');
            setTimeout(() => {
                parceirosImageArray.map(parceiro => parceiro.classList.add('parceiros-image-effect'));
            }, 100);
        } else {
            parceirosButton.innerHTML = `<img src='${icon_plus}' alt='' />`;
            parceirosContent.classList.remove('display-parceiros-content');
            parceirosImageArray.map(parceiro => parceiro.classList.remove('parceiros-image-effect'));
        }
    }, [isParceirosDisplayed]);

    return (

        <div>
            
            <Helmet>
                <title>Frutyl | Serviços</title>
            </Helmet>

            <MainBanner
                page='servicos'
                pageMainTitle='Serviços oferecidos'
                pageSubtitle={`Veja quais são os serviços prestados pela Frutyl.${'\n'}Informe-se sobre os nossos locais de atendimento ao cliente.`}
                backgroundImage={banner_background_servicos}
                backgroundPosition={[{mobile: 'bottom center', desktop: 'top center'}]}
                additionalInformation={null}
            />

            <div className='main-container'>

                <div className='main-title'>
                    <h1>ATIVIDADES COMERCIAIS</h1>
                </div>

                <div className='servicos-content'>

                    <div className='servicos-summary'>
                        <p>
                            Acreditamos na importância de manter uma relação de confiança e transparência com os nossos clientes. Desta forma, detalhamos algumas informações a respeito dos nossos mais variados serviços comerciais.
                        </p>
                    </div>

                    <div className='servicos-atendimento'>
                        {
                            windowWidth < 700 ? <h2>ATENDIMENTO &<br /> ÁREAS DE ATUAÇÃO</h2> : <h2>ATENDIMENTO & ÁREAS DE ATUAÇÃO</h2>
                        }
                        <p>
                            Nosso produto é comercializado de duas maneiras: através do <span>atacado</span> e do <span>varejo</span>.
                        </p>
                        <p>
                            Atendemos clientes de pequeno, médio e grande porte na cidade de <span>Porto Alegre e região metropolitana</span>.
                        </p>
                        <p>
                            Possuímos uma frota de caminhões para atender nossos clientes sempre quando necessário. Nossos clientes atuam em diversos ramos comerciais, entre eles: padarias, mercados, armazéns, bares, restaurantes, lancherias, confeitarias, hotéis e escolas.
                        </p>
                        <p>
                            O nosso atendimento é feito de maneira pessoal, nos locais citados acima, possibilitando negociar diretamente com o cliente no que diz respeito à quantidade, necessidade e preço do serviço.
                        </p>
                        <p>
                            A Frutyl também disponibiliza um <span>atendimento presencial na sede da empresa</span>, onde o mesmo processo de compra e venda pode ser realizado.
                        </p>
                        <div className='atendimento-info'>
                            <h3>Horário de atendimento</h3>
                            <p><span>Segunda a sexta-feira</span> — 7h até às 11:30h e 14h até às 17h</p>
                            <p><span>Sábados</span> — 7h até às 11:30h</p>
                            <h3>Endereço do local</h3>
                            <p>Rua Augusto Rocha, 80. Bairro São Geraldo</p>
                            <p>Gravataí, Rio Grande do Sul</p>
                            <div className='atendimento-image'>
                                <img src={frutyl_current} alt='Local de atendimento presencial' />
                                <h4>Vista área do local onde ocorre o atendimento presencial</h4>
                            </div>
                        </div>
                    </div>

                    <div className='servicos-feiras'>
                        <h2>MERCADÃO DO PRODUTOR</h2>
                        <p>
                            Há mais de 3 décadas nossa empresa participa semanalmente do Mercadão do Produtor na cidade de Porto Alegre.
                        </p>
                        <p>
                            Após tanto tempo se fazendo presente na maior feira da capital gaúcha, temos o orgulho de poder dizer que conhecemos cada cliente pelo nome e qual o tipo de produto o agrada. </p> 
                        <p>
                            Certamente, ir ao Mercadão do Produtor não é apenas uma ocasião de trabalho para nós, mas também um momento de alegria e de reencontrar amigos.
                        </p>
                        <div className='servicos-feiras-image'>
                            <img src={icon_basket_fruit} alt='' />
                        </div>
                        <div className='feiras-container' id='feiras-container'>
                            <p>Atualmente, a Frutyl participa do Mercadão do Produtor <span>três vezes por semana</span>: às quintas-feiras, às sextas-feiras e aos sábados.</p>
                            <p>Selecione o dia da feira que você deseja obter mais informações.</p>
                            <div className='feiras-buttons'>
                                <button className='feiras-button' id='01' onClick={(event) => {
                                    feirasHandler(event, '01')
                                }}>Quinta-feira</button>
                                <button className='feiras-button' id='02' onClick={(event) => {
                                    feirasHandler(event, '02')
                                }}>Sexta-feira</button>
                                <button className='feiras-button' id='03' onClick={(event) => {
                                    feirasHandler(event, '03')
                                }}>Sábado</button>
                            </div>
                            <div className='feiras' id='feiras'>
                                    {
                                        feira.length === 0 ? '' : 
                                        <div className='feiras-content'>
                                            <div className='feiras-current'>
                                                <p>você está visualizando o mercado de <span>{feira.feiraDayOfTheWeek}</span></p>
                                            </div>
                                            <h2>{feira.feiraName}</h2>
                                            <div className='feiras-service-hours'>
                                                <h3>Horário de atendimento</h3>
                                                <p>{feira.feiraServiceHours}</p>
                                            </div>
                                            <div className='feiras-address'>
                                                <h3>Endereço da feira</h3>
                                                {
                                                    feira.feiraAddress.map((address) => {
                                                        console.log(address.addressDescription);
                                                        return (
                                                            <p key={address.addressDescriptionKey}>{address.addressDescription}</p>
                                                        )
                                                    })
                                                }
                                            </div>
                                            <div className='feira-map'>
                                                <Map 
                                                    latitudeValue={feira.feiraMap.feiraLatitude}
                                                    longitudeValue={feira.feiraMap.feiraLongitude}
                                                />
                                            </div>
                                            <div className='close-feiras'>
                                                <button onClick={handleCloseFeiras}>
                                                    fechar visualização
                                                </button>
                                            </div>
                                            
                                        </div>
                                    }
                            </div>
                        </div>
                    </div>

                    <div className='servicos-responsabilidade'>
                        <h2>RESPONSABILIDADES SOCIAIS</h2>
                        <p>
                            Nossa empresa tem o orgulho de possuir uma ideologia vinculada com a responsabilidade social.
                        </p>
                        <p> 
                            Ao longo do período anual, realizamos diversas doações para instituições e clientes próximos. A fim de contextualizar, regularmente disponibilizamos algumas caixas de banana ao SESC-RS, onde este as distribui para escolas, creches e outros estelecimentos.
                        </p>
                        <p>
                            Também participamos de outras ações solidárias realizadas com a colaboração da Defesa Civil, sempre visando ajudar famílias desamparadas.
                        </p>
                        <div className='responsabilidade-image'>
                            <img src={frutyl_awards} alt='Prêmios adquiridos pela Frutyl' />
                            <h4>Premiações de participação voluntária</h4>
                        </div>
                        <p>
                            Temos a sensibilidade e o compromisso de colaborar com a sociedade através de ações afirmativas. Como exemplo, fornecemos preços ajustados aos haitianos que imigraram para o nosso estado nos últimos anos, em decorrência da crise humanitária e política no país caribenho. 
                        </p>
                        <p>
                            A gratidão dos imigrantes haitianos pode ser observada durante o dia a dia da Frutyl, tendo em vista que regularmente somos procurados por membros de sua comunidade para prestar serviços e realizar vendas.
                        </p>
                        <p>
                            Além disso, aos sábados pela manhã comercializamos bananas verde <span className='responsabilidade-social-italic'>in natura</span> para imigrantes venezuelanos, haitianos, senegaleses e angolanos; por se tratar de um alimento básico e importante para esses povos.
                        </p>
                        <div className='responsabilidade-symbol'>
                            <img src={icon_together} alt='' />
                        </div>
                    </div>

                </div>

            </div>

            <div className='parceiros-container'>
                <div className='parceiros-summary'>
                    <h2>NOSSOS PARCEIROS</h2>
                    <p>
                        Em decorrência de muito trabalho e companheirismo, tivemos o privilégio de estabelecer laços e criar relações com muitos clientes, consumidores e fornecedores.
                    </p>
                    <p>
                        Atualmente, a Frutyl conta com mais de 100 parceiros comerciais. Clique no botão abaixo para conhecer alguns parceiros da nossa empresa.
                    </p>
                    <div className='parceiros-button-wrapper'>
                        <button id='parceiros-button' onClick={() => setIsParceirosDisplayed(!isParceirosDisplayed)}></button>
                    </div>
                </div>
                <div className='parceiros-content' id='parceiros-content'>
                    {
                        parceiros_data.map((parceiro) => {
                            return (
                                <div key={parceiro.parceiroId} className='parceiros-image-wrapper'>
                                    <img className='parceiros-image' src={parceiro.parceiroLogo} alt={parceiro.parceiroName} /> 
                                </div>
                            )
                        })
                    }
                </div>
            </div>

            <Spotlight 
                spotlightLink='/contato'
                spotlightTitle='CONTATO'
                spotlightDescription={`Ficou com alguma dúvida a respeito dos nossos serviços?${`\n`}Gostaria de solicitar uma parceria?${`\n\n`}Fale conosco.`}
                spotlightButton='ENTRAR EM CONTATO'
            />

        </div>
    );
}

export default Servicos;