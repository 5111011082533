import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

// Pages
import Home from './pages/Home/Home';
import Sobre from './pages/Sobre/Sobre';
import Produtos from './pages/Produtos/Produtos';
import Servicos from './pages/Servicos/Servicos';
import Receitas from './pages/Receitas/Receitas';
import Beneficios from './pages/Beneficios/Beneficios';
import Contato from './pages/Contato/Contato';
import PageNotFound from './pages/PageNotFound/PageNotFound';

// Components
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';

// Helpers
import ScrollToTop from './helpers/ScrollToTop'; // when loading a new page

function App() {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const updateWindowWidth = () => setWindowWidth(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', updateWindowWidth);
    return () => window.removeEventListener('resize', updateWindowWidth);
  });

  return (
    <div className="App">
      <HelmetProvider>
        <Router>
          <ScrollToTop>
            <Navbar />
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/sobre' element={<Sobre />} />
              <Route path='/produtos' element={<Produtos />} />
              <Route path='/servicos' element={<Servicos windowWidth={windowWidth} />} />
              <Route path='/receitas' element={<Receitas />} />
              <Route path='/beneficios' element={<Beneficios />} />
              <Route path='/contato' element={<Contato />} />
              <Route path='*' element={<PageNotFound />} />
            </Routes>
            <Footer windowWidth={windowWidth} />
          </ScrollToTop>
        </Router>
      </HelmetProvider>
    </div>
  );

}

export default App;
