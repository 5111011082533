import React from 'react';
import './Home.css';
import { Helmet } from 'react-helmet-async';

// Components
import MainBanner from '../../components/MainBanner/MainBanner';
import Card from '../../components/Card/Card';
import Spotlight from '../../components/Spotlight/Spotlight';

// Images
import banner_background_home from '../../assets/images/banner-background-home.jpg';
import card_produtos from '../../assets/images/card-produtos.jpg';
import card_servicos from '../../assets/images/card-servicos.jpg';
import card_receitas from '../../assets/images/card-receitas.jpg';
import card_beneficios from '../../assets/images/card-beneficios.jpg';

const Home = () => {

    return (
        
        <div>
            
            <Helmet>
                <title>Frutyl | Home</title>
            </Helmet>
            
            <MainBanner 
                page='home'
                pageMainTitle='Boas vindas ao site da Frutyl'
                pageSubtitle='Nossa empresa é referência no comércio e transporte de bananas em Porto Alegre e região metropolitana.'
                backgroundImage={banner_background_home}
                backgroundPosition={[{mobile: '10% top', desktop: 'center 50%'}]}
                additionalInformation={['Status atual:', 'Em funcionamento — desde 1963.']}
            />

            <div className='home-main'>

                <h2>Navegue pelo nosso site!</h2>

                <div className='cards'>
                    <Card 
                        cardLink='/produtos'
                        cardTitle='NOSSOS PRODUTOS'
                        cardImage={card_produtos}
                        cardImageAlt='Produtos'
                        cardDescription='A Frutyl comercializa dois tipos de banana: Caturra e Prata.'
                    />
                    <Card 
                        cardLink='/servicos'
                        cardTitle='NOSSOS SERVIÇOS'
                        cardImage={card_servicos}
                        cardImageAlt='Serviços'
                        cardDescription='Conheça os nossos pontos de vendas e locais de distribuição.'
                    />
                    <Card 
                        cardLink='/receitas'
                        cardTitle='RECEITAS DE BANANA'
                        cardImage={card_receitas}
                        cardImageAlt='Receitas'
                        cardDescription='A banana é uma fruta bastante versátil para a concepção e produção dos mais variados tipos de receitas culinárias.'
                    />
                    <Card
                        cardLink='/beneficios'
                        cardTitle='BENEFÍCIOS DA BANANA'
                        cardImage={card_beneficios}
                        cardImageAlt='Benefícios'
                        cardDescription='A banana é uma fruta extremamente nutritiva, popular e rica em nutrientes essenciais para a saúde.'
                    />
                </div>

            </div>

            <Spotlight 
                spotlightLink='/contato'
                spotlightTitle='CONTATO'
                spotlightDescription={`Gostaria de realizar um pedido?${'\n'}Fazer uma crítica?${'\n'}Tirar uma dúvida?${'\n\n'}Estamos sempre à disposição.`}
                spotlightButton='ENTRAR EM CONTATO'
            />
            
        </div>
        
    );
}

export default Home;