export const feiras_data = [
    {
        "feiraId": "01",
        "feiraName": "Feira do Parcão",
        "feiraDayOfTheWeek": "quinta-feira",
        "feiraServiceHours": "13:30h até às 20h",
        "feiraAddress": [
            {   
                addressDescriptionKey: 1,
                addressDescription: "Rua Comendador Caminha."
            },
            {
                addressDescriptionKey: 2,
                addressDescription: "Estacionamento Parcão."
            },
            {
                addressDescriptionKey: 3,
                addressDescription: "Porto Alegre, Rio Grande do Sul."
            }
        ],
        "feiraMap": {
            feiraLatitude: "-30.026371488342154",
            feiraLongitude: "-51.201210122889954"
        }
    },
    {
        "feiraId": "02",
        "feiraName": "Feira da Restinga",
        "feiraDayOfTheWeek": "sexta-feira",
        "feiraServiceHours": "14h até às 20h",
        "feiraAddress": [
            {   
                addressDescriptionKey: 1,
                addressDescription: "Avenida Macedônia."
            },
            {
                addressDescriptionKey: 2,
                addressDescription: "Em frente ao CREAS."
            },
            {
                addressDescriptionKey: 3,
                addressDescription: "Porto Alegre, Rio Grande do Sul."
            }
        ],
        "feiraMap": {
            feiraLatitude: "-30.15075845306237", // -30.15075845306237, -51.14218999534608
            feiraLongitude: "-51.14218999534608"
        }
    },
    {
        "feiraId": "03",
        "feiraName": "Feira da Epatur",
        "feiraDayOfTheWeek": "sábado",
        "feiraServiceHours": "6h até às 14h",
        "feiraAddress": [
            {   
                addressDescriptionKey: 1,
                addressDescription: "Avenida Loureiro da Silva."
            },
            {
                addressDescriptionKey: 2,
                addressDescription: "Largo Zumbi dos Palmares."
            },
            {
                addressDescriptionKey: 3,
                addressDescription: "Porto Alegre, Rio Grande do Sul."
            }
        ],
        "feiraMap": {
            feiraLatitude: "-30.03802231312377",
            feiraLongitude: "-51.22628284349999"
        }
    }
];

export default feiras_data;