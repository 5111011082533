// Images
import parceiro_supermercadoFraga from '../../assets/images/parceiro-supermercado-fraga.jpg';
import parceiro_mercadoSuperTop from '../../assets/images/parceiro-mercado-super-top.jpg';
import parceiro_hortifrutiGuimaraes from '../../assets/images/parceiro-hortifruti-guimaraes.jpg';
import parceiro_hortifrutiNovaSafra from '../../assets/images/parceiro-hortifruti-nova-safra.png'
import parceiro_hortifrutiVitoria from '../../assets/images/parceiro-hortifruti-vitoria.jpg';
import parceiro_supermercadoEsteves from '../../assets/images/parceiro-supermercado-esteves.jpg';
import parceiro_kunstHortifrutigranjeiros from '../../assets/images/parceiro-kunst-hortifrutigranjeiros.png';


export const parceiros_data = [
    {
        "parceiroId": "01",
        "parceiroName": "Supermercado Fraga",
        "parceiroLogo": parceiro_supermercadoFraga
    },
    {
        "parceiroId": "02",
        "parceiroName": "Mercado Super Top",
        "parceiroLogo": parceiro_mercadoSuperTop
    },
    {
        "parceiroId": "03",
        "parceiroName": "Hortifruti Guimarães",
        "parceiroLogo": parceiro_hortifrutiGuimaraes
    },
    {
        "parceiroId": "04",
        "parceiroName": "Hortifruti Nova Safra",
        "parceiroLogo": parceiro_hortifrutiNovaSafra
    },
    {
        "parceiroId": "05",
        "parceiroName": "Hortifruti Vitória",
        "parceiroLogo": parceiro_hortifrutiVitoria
    },
    {
        "parceiroId": "06",
        "parceiroName": "Supermercado Esteves",
        "parceiroLogo": parceiro_supermercadoEsteves
    },
    {
        "parceiroId": "07",
        "parceiroName": "Kunst Hortifrutigranjeiros",
        "parceiroLogo": parceiro_kunstHortifrutigranjeiros
    }
];

export default parceiros_data;