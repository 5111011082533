import React, { useEffect, useState } from 'react';
import './Produtos.css';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

// Components
import MainBanner from '../../components/MainBanner/MainBanner';
import ProdutosTable from '../../components/ProdutosTable/ProdutosTable';
import ProdutosSlider from '../../components/ProdutosSlider/ProdutosSlider';
import Spotlight from '../../components/Spotlight/Spotlight';

// Images
import banner_background_produtos from '../../assets/images/banner-background-produtos.jpg';
import icon_banana_01 from '../../assets/images/icon-banana-01.png';
import produtos_images from './produtos_images';

const Produtos = () => {

    const [isCaturraDisplayed, setIsCaturraDisplayed] = useState(true);
    const [isPrataDisplayed, setIsPrataDisplayed] = useState(false);

    const displayCaturra = () => {
        setIsCaturraDisplayed(true);
        setIsPrataDisplayed(false);
    }

    const displayPrata = () => {
        setIsPrataDisplayed(true);
        setIsCaturraDisplayed(false);
    }

    useEffect(() => {
        if (isCaturraDisplayed) {
            document.getElementById('caturra').classList.add('banana-active');
            document.getElementById('prata').classList.remove('banana-active');
            document.getElementById('button-banana-caturra').classList.add('button-banana-active');
            document.getElementById('button-banana-prata').classList.remove('button-banana-active');
        } else {
            document.getElementById('prata').classList.add('banana-active');
            document.getElementById('caturra').classList.remove('banana-active');
            document.getElementById('button-banana-prata').classList.add('button-banana-active');
            document.getElementById('button-banana-caturra').classList.remove('button-banana-active');
        }
    }, [isCaturraDisplayed, isPrataDisplayed]);

    const expandAdditionalInfo = (event) => {
        const button = event.target;
        const additionalInfo = button.nextElementSibling;
        additionalInfo.classList.toggle('additional-info-active');
        if (additionalInfo.classList.contains('additional-info-active')) {
            button.style.backgroundColor = '#2faf5b';
            additionalInfo.style.maxHeight = additionalInfo.scrollHeight + 'px';
        } else {
            button.style.backgroundColor = '#575757';
            additionalInfo.style.maxHeight = '0px';
        }
    }

    return (

        <div>
            
            <Helmet>
                <title>Frutyl | Produtos</title>
            </Helmet>

            <MainBanner
                page='produtos'
                pageMainTitle='Produtos comercializados'
                pageSubtitle={`Informe-se a respeito dos produtos fornecidos pela Frutyl.${'\n'}Conheça o processo de transporte e armazenamento dos nossos produtos.`}
                backgroundImage={banner_background_produtos}
                backgroundPosition={[{mobile: '60% center', desktop: 'center center'}]}
                additionalInformation={null}
            />

            <div className='main-container'>

                <div className='main-title'>
                    <h1>NOSSOS PRODUTOS</h1>
                </div>

                <div className='produtos-content'>

                    <div className='produtos-summary'>
                        <p>
                            A Frutyl comercializa dois tipos de bananas: <span>Caturra</span> & <span>Prata</span>.
                        </p>
                        <div>
                            <button onClick={displayCaturra} id='button-banana-caturra'>BANANA CATURRA</button>
                            <button onClick={displayPrata} id='button-banana-prata'>BANANA PRATA</button>
                        </div>
                    </div>

                    <div className='produtos-bananas'>

                        <div className='produtos-banana banana-active' id='caturra'>
                            <h2>BANANA CATURRA</h2>
                            <p>
                                A banana caturra - também conhecida como banana nanica - é uma banana macia que mede cerca de 15~25 centímetros e possui uma polpa bastante doce. Sua casca é fina e seu aroma é agradável.
                            </p>
                            <p>
                                A banana caturra possui uma grande quantidade de fibras que auxiliam no processo de normalização do trânsito intestinal, além de acalmar o estômago e ajudar no processo de digestão.
                            </p>
                            <div className='produtos-table-wrapper'>
                                <ProdutosTable 
                                    caloriasValue='68 kcal'
                                    proteinasValue='0,84 g'
                                    vitamicaCValue='20 mg'
                                    potassioValue='370 mg'
                                    carboidratosValue='16 g'
                                    sodioValue='0 mg'
                                    gorduraSaturadaValue='0 g'
                                    gorduraTotalValue='0,28 g'
                                />
                            </div>
                            
                            <h3 className='slider-title'><span>Galeria — </span>Banana caturra</h3>
                            <div className='container-slider'>
                                <ProdutosSlider 
                                    bananaType={produtos_images[0].bananaType}
                                    bananaImages={produtos_images[0].bananaImages}
                                />
                            </div>

                        </div>
                        
                        <div className='produtos-banana' id='prata'>
                            <h2>BANANA PRATA</h2>
                            <p>
                                A banana prata é muitíssimo recomendada para quem está buscando emagrecer. Normalmente, as bananas pratas podem medir entre 9~15 centímetros.
                            </p>
                            <p>
                                Esse tipo de banana possui um formato reto e uma polpa bastante amarelada, além de ser muito consistente. É menos doce do que a banana caturra, sendo menos recomendada para a concepção de receitas doces.
                            </p>
                            <div className='produtos-table-wrapper'>
                                <ProdutosTable
                                    caloriasValue='39,6 kcal'
                                    proteinasValue='0,52 g'
                                    vitamicaCValue='20 mg'
                                    potassioValue='370 mg'
                                    carboidratosValue='9,12 g'
                                    sodioValue='0 mg'
                                    gorduraSaturadaValue='0 g'
                                    gorduraTotalValue='0,12 g'
                                />
                            </div>
                            
                            <h3 className='slider-title'><span>Galeria — </span>Banana prata</h3>
                            <div className='container-slider'>
                                <ProdutosSlider 
                                    bananaType={produtos_images[1].bananaType}
                                    bananaImages={produtos_images[1].bananaImages}
                                />
                            </div>

                        </div>
                        
                    </div>

                    <div className='produtos-contact'>
                        <img src={icon_banana_01} alt='' />
                        <p>Ficou com alguma dúvida a respeito dos nossos produtos? Gostaria de realizar um pedido?</p>
                        <br />
                        <p>Entre em contato conosco.</p>
                        <Link to='/contato'>
                            <button>FALE COM A FRUTYL</button>
                        </Link>
                    </div>

                </div>

            </div>

            <div className='produtos-additional-info'>

                <div className='additional-info-title'>
                    <h2>PRODUÇÃO E LOGÍSTICA</h2>
                    <p>Disponibilizamos algumas informações a respeito de como é feita a produção e a logística dos nossos produtos.</p>
                </div>
                
                <div className='additional-info'>
                    <button onClick={expandAdditionalInfo}>CLIMATIZAÇÃO DOS PRODUTOS</button>
                    <div>
                        <p>
                            Em condições naturais, o processo de amadurecimento da banana é desuniforme, devido a formação das pencas que são apresentadas entre 10 a 15 dias de diferença, em função do florescimento e do desenvolvimento da fruta. Dessa forma, a climatização da banana é importantíssima, pois permitirá que o amadurecimento seja uniforme, ou seja, toda a penca estará apta para consumo ao mesmo tempo.
                        </p>
                        <p>
                            Nossa empresa possui quatro câmaras de climatização e as utiliza, como dito, para controlar o amadurecimento, em períodos que duram de 3 a 5 dias, levando em conta fatores como temperatura, umidade relativa, ar atmosférico, circulação de ar, exaustão e considerando as estações do ano. A tarefa de climatizar bananas é complexa e implica em anos de experiência, pois nenhuma fruta é igual. Para tanto, nossa empresa possui um funcionário especializado em climatização de bananas.
                        </p>
                    </div>
                </div>

                <div className='additional-info'>
                    <button onClick={expandAdditionalInfo}>PROCESSO DE ARMAZENAMENTO</button>
                    <div>
                        <p>
                            Diariamente, nossos fornecedores transportam - através de caminhões - toneladas de bananas até nossa sede, onde é realizada a descarga dos produtos diretamente para as nossas câmaras; por isso, possuímos uma equipe especializada para a execução desta tarefa. O armazenamento ocorre por caixas sobrepostas em número de cinco, permitindo a visualização do produto e impedindo danos ao mesmo. As caixas são de plástico e higienizadas, consoante legislação vigente. Nossa empresa possui quatro câmaras de climatização com capacidade de armazenamento de aproximadamente 50 toneladas.
                        </p>
                    </div>
                </div>

                <div className='additional-info'>
                    <button onClick={expandAdditionalInfo}>TRANSPORTE E DISTRIBUIÇÃO</button>
                    <div>
                        <p>
                            O transporte do produto ocorre através de caminhões que vêm de diversas localidades do Brasil. Nossa empresa possui caminhões próprios tanto para transporte como para distribuição. A distribuição - ou a comercialização da banana - ocorre diariamente em Porto alegre e região metropolitana, sendo que cada caminhão possui uma equipe especializada para o cumprimento da tarefa. A comercialização do produto também ocorre na sede da nossa empresa, através da procura de inúmeros clientes.
                        </p>
                    </div>
                </div>

            </div>

            <Spotlight 
                spotlightLink='/servicos'
                spotlightTitle='SERVIÇOS'
                spotlightDescription={`Quer conhecer um pouco mais sobre os nossos meios de produção?${'\n'}Detalhamos as formas com que é realizado o atendimento aos nossos clientes.${`\n\n`}Clique para saber mais.`}
                spotlightButton='SERVIÇOS DISPONÍVEIS'
            />

        </div>
    );
}

export default Produtos;