import React from 'react';
import './PageNotFound.css'
import { Link } from 'react-router-dom';

// Images
import icon_page_not_found from '../../assets/images/icon-error.png';

const PageNotFound = () => {

    return (
        <div>
            <div className='page-not-found-wrapper'>
                <div className='page-not-found-content'>
                    <img src={icon_page_not_found} alt='' />
                    <h1>Página não encontrada!</h1>
                    <p>Parece que você digitou um endereço inválido do site da Frutyl.</p>
                    <p>Volte para a página inicial para continuar navegando no nosso site.</p>
                    <Link to='/'>
                        <button>Voltar a página inicial</button>
                    </Link>
                    <p className='page-not-found-copyright'>
                        Copyright <span>&copy;</span> {new Date().getFullYear()} <br />
                        frutyl.com
                    </p>
                </div>
            </div>
        </div>
    )

}

export default PageNotFound;