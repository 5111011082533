import React from 'react';
import './ProdutosTable.css';

const ProdutosTable = ({ caloriasValue, proteinasValue, vitamicaCValue, potassioValue, carboidratosValue, sodioValue, gorduraSaturadaValue, gorduraTotalValue }) => {

    return (

        <table className='produtos-table'>
            <caption>Informações nutricionais</caption>
            <tbody>
                <tr>
                    <td>Calorias</td>
                    <td>{caloriasValue}</td>
                </tr>
                <tr>
                    <td>Proteínas</td>
                    <td>{proteinasValue}</td>
                </tr>
                <tr>
                    <td>Vitamina C</td>
                    <td>{vitamicaCValue}</td>
                </tr>
                <tr>
                    <td>Potássio</td>
                    <td>{potassioValue}</td>
                </tr>
                <tr>
                    <td>Carboidratos</td>
                    <td>{carboidratosValue}</td>
                </tr>
                <tr>
                    <td>Sódio</td>
                    <td>{sodioValue}</td>
                </tr>
                <tr>
                    <td>Gordura saturada</td>
                    <td>{gorduraSaturadaValue}</td>
                </tr>
                <tr>
                    <td>Gordura total</td>
                    <td>{gorduraTotalValue}</td>
                </tr>
            </tbody>
        </table>

    );

}

export default ProdutosTable;