import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import './ContatoForm.css';

const ContatoForm = ({ setFormOutcome }) => {

    const form = useRef();
    const [formStatus, setFormStatus] = useState('Enviar e-mail');

    const sendEmail = (e) => {
        e.preventDefault();
        setFormStatus('Enviando...');
        // setFormOutcome(true);
        console.log(e.target);

        emailjs.sendForm(
            process.env.REACT_APP_SERVICE_ID, 
            process.env.REACT_APP_TEMPLATE_ID,
            form.current,
            process.env.REACT_APP_PUBLIC_KEY
        ).then((result) => {
            setFormOutcome(true);
            setFormStatus('Enviar');
            e.target.reset();
            console.log(result.text);
        }, (error) => {
            setFormOutcome(false);
            setFormStatus('Enviar');
            e.target.reset();
            console.log(error.text);
        });
        // e.target.reset();
    };

    return (
        <form ref={form} onSubmit={sendEmail} className='contato-form'>
            <div>
                <label>Nome</label>
                <input type='text' name='user_name' placeholder='Informe o seu nome' required />
            </div>
            <div>
                <label>E-mail</label>
                <input type='email' name='user_email' placeholder='Informe o seu e-mail' required />
            </div>
            <div>
                <label>Mensagem</label>
                <textarea name='user_message' placeholder='Escreva a sua mensagem' rows='7' required />
            </div>
            <button type='submit'>{formStatus}</button>
        </form>
    );

}

export default ContatoForm;