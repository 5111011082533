export const beneficios_data = [
    {
        "beneficioId": "1",
        "beneficioTitle": "Sistema de imunidade",
        "beneficioContent": [
            {
                "contentId": "1",
                "contentText": <>A banana é uma fruta rica em <span>vitamina C e vitamina B6</span>. Essas vitaminas ajudam na formação de anticorpos e de células de defesa no nosso corpo.</>
            },
            {
                "contentId": "2",
                "contentText": <>O fortalecimento imunológico proporcionado por essas vitaminas é essencial para prevenir e, se necessário, <span>combater resfriados, gripes e outras infecções</span> mais severas.</>
            }
        ]
    },
    {
        "beneficioId": "2",
        "beneficioTitle": "Metabolismo",
        "beneficioContent": [
            {
                "contentId": "1",
                "contentText": <>Os nutrientes presentes na vitamina B6 são importantíssimos para o <span>bom funcionamento do nosso metabolismo</span> durante o dia a dia.</>
            },
            {
                "contentId": "2",
                "contentText": <>As reações químicas oriundas dos <span>nutrientes</span> encontrados na banana são <span>distribuídas pelo nosso corpo</span> e acabam sendo utilizadas por todas as nossas células.</>
            }
        ]
    },
    {
        "beneficioId": "3",
        "beneficioTitle": "Energia e disposição",
        "beneficioContent": [
            {
                "contentId": "1",
                "contentText": <>A banana é extremamente recomendada para <span>consumo antes da prática de exercícios físicos</span>.</>
            },
            {
                "contentId": "2",
                "contentText": <>A fruta fornece <span>grandes quantidades de energia</span> para o treino, melhorando o rendimento e prevenindo cãibras.</>
            },
            {
                "contentId": "3",
                "contentText": <>A presença de cálcio, potássio e magnésio na banana acaba auxiliando na <span>redução de dores musculares</span> durante atividades físicas.</>
            }
        ]
    },
    {
        "beneficioId": "4",
        "beneficioTitle": "Sistema digestivo",
        "beneficioContent": [
            {
                "contentId": "1",
                "contentText": <>A banana ajuda na prevenção da formação de úlceras gástricas. Além disso, a fruta <span>reequilibra a flora intestinal</span>, reduzindo a produção de colesterol pelo fígado e ajudando na manutenção da integridade da mucosa intestinal.</>
            },
            {
                "contentId": "2",
                "contentText": <>A biomassa da banana não é completamente absolvida pelo intestino, promovendo ácidos graxos de cadeia curta, sendo este um nutriente <span>essencial para a produção de bactérias intestinais benéficas</span>.</>
            }
        ]
    },
    {
        "beneficioId": "5",
        "beneficioTitle": "Ansiedade e depressão",
        "beneficioContent": [
            {
                "contentId": "1",
                "contentText": <>A banana é rica em triptofano, um aminoácio essencial que <span>aumenta a produção de serotonina</span> no nosso sistema nervoso central.</>
            },
            {
                "contentId": "2",
                "contentText": <>A serotonina, por sua vez, age como um neurotransmissor importantíssimo no processo de <span>regulamento do humor, apetite e sono</span> no nosso corpo, sendo utilizado constantemente para o tratamento de casos de depressão e ansiedade.</>
            }
        ]
    },
    {
        "beneficioId": "6",
        "beneficioTitle": "Coração",
        "beneficioContent": [
            {
                "contentId": "1",
                "contentText": <>A abundância de potássio na banana ajuda no <span>regulamento dos batimentos cardíacos</span>, mantendo a regularidade e o funcionamento do nosso coração.</>
            },
            {
                "contentId": "2",
                "contentText": <>Além disso, o potássio controla os efeitos do sódio no nosso organismo, prevenindo problemas de hipertensão e <span>reduzindo as chances de infarto e outras doenças cardíacas</span>.</>
            }
        ]
    },
    {
        "beneficioId": "7",
        "beneficioTitle": "Saciedade",
        "beneficioContent": [
            {
                "contentId": "1",
                "contentText": <>As fibras presentes nos nutrientes da banana <span>desaceleram o esvaziamento gástrico</span>, nos trazendo uma sensação de saciedade.</>
            },
            {
                "contentId": "2",
                "contentText": <>Dessa forma, consumir a banana regularmente <span>diminui a sensação de fome</span>, favorecendo a perda de peso indesejado.</>
            }
        ]
    },
    {
        "beneficioId": "8",
        "beneficioTitle": "Sono e insônia",
        "beneficioContent": [
            {
                "contentId": "1",
                "contentText": <>A casca e a polpa da banana contêm nutrientes ricos em <span>serotonina e dopamina</span>.</>
            },
            {
                "contentId": "2",
                "contentText": <>A serotonina contribui diretamente para a <span>sensação de bem-estar e felicidade</span>. A dopamina, por outro lado, desempenha um papel importantíssimo no nosso cérebro como um neurotransmissor, impactando o nosso <span>humor, capacidade de concentração e estabilidade emocional</span>.</>
            },
            {
                "contentId": "3",
                "contentText": <>Os nutrientes presentes na banana contribuem para que o nosso organismo consiga gerar melatonina, um hormônio responsável pela qualidade do sono e que <span>ajuda no combate à insônia</span>.</>
            }
        ]
    },
    {
        "beneficioId": "9",
        "beneficioTitle": "Saúde dos olhos",
        "beneficioContent": [
            {
                "contentId": "1",
                "contentText": <>Um dos nutrientes encontrados na banana é a <span>vitamina A</span>. Incapaz de ser sintetizada pelo nosso organismo, essa vitamina precisa ser obtida através da ingestão de alimentos — a banana é, sempre, uma opção acessível.</>
            },
            {
                "contentId": "2",
                "contentText": <>Essa vitamina também ajuda na preservação das nossas membranas oculares, <span>melhorando a saúde dos olhos e a qualidade da visão</span>.</>
            },
            {
                "contentId": "3",
                "contentText": <>Além disso, o consumo regular da banana é <span>benéfico para a visão em ambientes escuros e de baixa luminosidade</span>, evitando problemas como a cegueira noturna.</>
            }
        ]
    },
    {
        "beneficioId": "10",
        "beneficioTitle": "Praticidade",
        "beneficioContent": [
            {
                "contentId": "1",
                "contentText": <>Além de todos esses benefícios nutricionais, a banana é uma <span>fruta extremamente prática</span> e rápida de se incluir em qualquer dieta.</>
            },
            {
                "contentId": "2",
                "contentText": <>Podendo ser consumida <i>in natura</i> ou através da confecção de receitas, a banana é <span>uma das frutas mais democráticas</span> que existe.</>
            },
            {
                "contentId": "3",
                "contentText": <>A banana tem um <span>sabor incrível</span>, sendo fácil de transportar e consumir, além de estar disponível durante o ano inteiro.</>
            }
        ]
    }
];

export default beneficios_data;