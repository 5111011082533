import React, { useEffect, useState } from 'react';
import './Navbar.css';
import { Link } from 'react-router-dom';

// Images
import navbar_logo from '../../assets/images/logo-frutyl.png';
import navbar_hamburger_menu from '../../assets/images/hamburger-menu.png';

const Navbar = () => {

    const [isNavbarMobileOpen, setIsNavbarMobileOpen] = useState(false);
    const [userScrolled, setUserScrolled] = useState(false);

    const updateScroll = () => {
        const value = document.documentElement.scrollTop;
        if (value > 0) {
            setUserScrolled(true);
        } else {
            setUserScrolled(false);
        }
    };

    window.addEventListener('scroll', updateScroll);

    const navbarScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }

    useEffect(() => {
        if (isNavbarMobileOpen) {
            document.body.classList.add('disable-scroll');
        } else {
            document.body.classList.remove('disable-scroll');
        }
    }, [isNavbarMobileOpen]);

    return (
        <div className={`navbar-container ${userScrolled ? 'navbar-scrolled' : ''}`}>

            <div className='navbar-logo'>
                <img src={navbar_logo} alt='Frutyl' onClick={navbarScrollToTop} />
            </div>

            <button className='navbar-hamburger' 
                    onClick={() => setIsNavbarMobileOpen(!isNavbarMobileOpen)}
            >
                <img src={navbar_hamburger_menu} alt='' />
            </button>

            <div className='navbar-desktop'>
                <ul>
                    <li>
                        <Link to='/'>Home</Link>
                    </li>
                    <li>
                        <Link to='/sobre'>Sobre</Link>
                    </li>
                    <li>
                        <Link to='/produtos'>Produtos</Link>
                    </li>
                    <li>
                        <Link to='/servicos'>Serviços</Link>
                    </li>
                    <li>
                        <Link to='/receitas'>Receitas</Link>
                    </li>
                    <li>
                        <Link to='/beneficios'>Benefícios</Link>
                    </li>
                    <li>
                        <Link to='/contato'>Contato</Link>
                    </li>
                </ul>
            </div>

            <div className={`navbar-mobile ${isNavbarMobileOpen ? 'display-navbar-mobile' : ''}`}>
                <ul className='navbar-mobile-menu'>
                    <li onClick={() => setIsNavbarMobileOpen(!isNavbarMobileOpen)}>
                        <Link to='/'>Home</Link>
                    </li>
                    <li onClick={() => setIsNavbarMobileOpen(!isNavbarMobileOpen)}>
                        <Link to='/sobre'>Sobre</Link>
                    </li>
                    <li onClick={() => setIsNavbarMobileOpen(!isNavbarMobileOpen)}>
                        <Link to='/produtos'>Produtos</Link>
                    </li>
                    <li onClick={() => setIsNavbarMobileOpen(!isNavbarMobileOpen)}>
                        <Link to='/servicos'>Serviços</Link>
                    </li>
                    <li onClick={() => setIsNavbarMobileOpen(!isNavbarMobileOpen)}>
                        <Link to='/receitas'>Receitas</Link>
                    </li>
                    <li onClick={() => setIsNavbarMobileOpen(!isNavbarMobileOpen)}>
                        <Link to='/beneficios'>Benefícios</Link>
                    </li>
                    <li onClick={() => setIsNavbarMobileOpen(!isNavbarMobileOpen)}>
                        <Link to='/contato'>Contato</Link>
                    </li>
                </ul>
                <button className='navbar-mobile-dismiss'
                        onClick={() => setIsNavbarMobileOpen(!isNavbarMobileOpen)}
                >
                    X
                </button>
            </div>

        </div>
    );
}

export default Navbar;