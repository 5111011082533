import React, { useEffect, useState } from 'react';
import './MainBanner.css';

const MainBanner = ({ page, pageMainTitle, pageSubtitle, backgroundImage, backgroundPosition, additionalInformation }) => {

    const wrapData = [{
        page: page,
        title: pageMainTitle,
        subtitle: pageSubtitle,
        image: backgroundImage,
        backgroundPosition: backgroundPosition,
        additionalInformation: additionalInformation
    }];
    
    const data = wrapData[0];
  
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    
    const updateWindowWidth = () => setWindowWidth(window.innerWidth);

    useEffect(() => {
        window.addEventListener('resize', updateWindowWidth);
        return () => window.removeEventListener('resize', updateWindowWidth);
    });

    return (

        <div className='main-banner-container' 
            style={{
                backgroundImage: `url(${backgroundImage})`,
                backgroundPosition: windowWidth < 1000 ? backgroundPosition[0].mobile : backgroundPosition[0].desktop,
                height: windowWidth < 1000 && page !== 'contato' ? '85vh' : // any page except 'contato' on mobile, height of 85vh
                        windowWidth >= 1000 && page !== 'contato' ? '100vh' : // any page except 'contato' on desktop, height of 100vh
                        windowWidth < 1000 && page === 'contato' ? '80vh' : // 'contato' page on mobile, height of 70vh
                        windowWidth >= 1000 && page === 'contato' ? '60vh' :// 'contato' page on desktop, height of 50vh
                        ''
            }}>
            <h1>{data.title}</h1>
            <h2>{data.subtitle}</h2>
            {
                data.additionalInformation == null ? 
                '' : 
                <div className='additional-information'>
                    <p>{additionalInformation[0]}</p>
                    <p>
                        <span></span> {additionalInformation[1]}
                    </p>
                </div>
            }
        </div>

    );

}

export default MainBanner;