import React from 'react';
import './Spotlight.css';
import { Link } from 'react-router-dom';

import spotlight_frame_image from '../../assets/images/spotlight-frame-image.png';
import icon_palm_tree from '../../assets/images/icon-palm-tree.png';

const Spotlight = ({ spotlightLink, spotlightTitle, spotlightDescription, spotlightButton }) => {

    return (

        <div className='spotlight-container'>

            <div className='spotlight-container-wrapper'>
                <div className='spotlight-title'>
                    <div className='spotlight-title-wrapper'>
                        <img src={spotlight_frame_image} alt='' />
                        <div>
                            <h2>FRUTYL</h2>
                            <h1>{spotlightTitle}</h1>
                        </div>
                    </div>
                </div>

                <div className='spotlight-description'>
                    <img src={icon_palm_tree} alt='' />
                    <p>{spotlightDescription}</p>
                    <Link to={spotlightLink}>
                        <button>{spotlightButton}</button>
                    </Link>
                </div>
            </div>

        </div>

    );

}

export default Spotlight;