import React from 'react';
import './Card.css';
import { Link } from 'react-router-dom';

const Card = ({ cardLink, cardTitle, cardImage, cardImageAlt, cardDescription }) => {

    return (

        <div className='card-container'>
            <h1>
                <span>#</span> {cardTitle}
            </h1>
            <Link to={cardLink}>
                <img src={cardImage} alt={cardImageAlt} />
            </Link>
            <p>
                {cardDescription}
            </p> 
        </div>

    );

}

export default Card;