import React, { useEffect, useState } from 'react';
import './Receitas.css';
import { Helmet } from 'react-helmet-async';

// Components
import MainBanner from '../../components/MainBanner/MainBanner';
import Spotlight from '../../components/Spotlight/Spotlight';

// Images
import banner_background_receitas from '../../assets/images/banner-background-receitas.jpg';
import icon_chef from '../../assets/images/icon-chef.png';
import copy_successful from '../../assets/images/icon-successful.png';
import copy_unsuccessful from '../../assets/images/icon-unsuccessful.png';
import icon_copy from '../../assets/images/icon-copy.png';
import icon_print from '../../assets/images/icon-print.png';

// Data
import receitas_data from './receitas_data';

const Receitas = () => {

    // console.log(receitas_data);

    const [receita, setReceita] = useState(false)
    const [copyOutcome, setCopyOutcome] = useState('');
    const [isCopyNotificationDisplayed, setIsCopyNotificationDisplayed] = useState(false);

    useEffect(() => {
        if (isCopyNotificationDisplayed) {
            document.getElementById('copy-notification').classList.add('display-copy-notification');
        } else {
            document.getElementById('copy-notification').classList.remove('display-copy-notification');
        }
    }, [isCopyNotificationDisplayed]);

    const handleReceita = (receitaId) => {
        handleReceitaButtons(receitaId);
        handleReceitaContent(receitaId);
        setTimeout(() => {
            const scrollToReceita = document.getElementById('scroll-to-receita');
            handleScroll(scrollToReceita);
        }, 100);
    }

    const handleReceitaButtons = (receitaId) => {
        const buttons = document.querySelectorAll('.receitas-button');
        buttons.forEach(button => {
            if (receitaId === button.id) {
                button.classList.add('receitas-button-active');
            } else {
                button.classList.remove('receitas-button-active');
            }
        })
    }

    const handleReceitaContent = (receitaId) => {
        receitas_data.find(receita => {
            if (receita.receitaId === receitaId) {
                setReceita(receita);
                console.log(receita);
            }
        })
    }

    const printReceita = () => {
        const elementToBePrinted = document.getElementById('receita-content').innerHTML;
        const newWindow = window.open('', '', '');
        newWindow.document.write('<html>');
        newWindow.document.write('<head>');
        newWindow.document.write('<title>frutyl.com/receitas</title>');
        newWindow.document.write('<style>');
        newWindow.document.write(`
            body {
                padding: 0;
                margin: 0;
                outline: none;
                box-sizing: border-box;
                font-family: 'Gotham Rounded', Verdana, sans-serif;
            }
            .receita-content {
                margin-top: 20px;
                padding: 0 20px;
            }
            .receita-content h2 {
                text-transform: uppercase;
                margin-bottom: 10px;
            }
            .receita-content h3 {
                margin-bottom: 0;
            }
            .receita-content img {
                display: none;
            } 
            .receita-content ul,
            .receita-content ol  {
                list-style: none;
                list-style-type: none;
                padding: 0;
            }
            .receita-content ul,
            .receita-content ol,
            .receita-content p {
                margin: 0;
            }
            .receita-content ul li span {
                display: none;
            }
            .receita-buttons {
                display: none;
            } 
        `);
        newWindow.document.write('</style>')
        newWindow.document.write('</head>');
        newWindow.document.write('<body>');
        newWindow.document.write('<div class="receita-content">')
        newWindow.document.write(elementToBePrinted);
        newWindow.document.write('</div>')
        newWindow.document.write('</body>');
        newWindow.document.write('</html>');
        setTimeout(() => {
            newWindow.document.close();
            newWindow.print();
        }, 100);
    }

    const handleCopyReceita = async (name, time) => {
        try {
            const receitaName = name.toUpperCase();
            const receitaIngredients = document.getElementById('receita-ingredients');
            const receitaCooking = document.getElementById('receita-cooking');
            const receitaTime = `Aproximadamente ${time}`;
            const receitaToBeCopied = `${receitaName}${'\n\n'}${receitaIngredients.innerText}${'\n\n'}${receitaCooking.innerText}${'\n\n'}Tempo de preparo${'\n'}${receitaTime}`;
            await copyToClipboard(receitaToBeCopied);
        } catch (err) {
            console.error(err);
        }
    }

    const copyToClipboard = async (toBeCopied) => {
        console.log(toBeCopied);
        if (navigator.clipboard && window.isSecureContext) {
            await navigator.clipboard.writeText(toBeCopied);
            notifyCopiedReceita(true, 2500);
        } else {
            const tempTextArea = document.createElement('textarea');
            tempTextArea.value = toBeCopied;
            tempTextArea.style.position = 'absolute';
            tempTextArea.style.left = '-9999999px';
            tempTextArea.style.top = '-9999999px';
            document.body.appendChild(tempTextArea);
            tempTextArea.select();
            tempTextArea.focus();
            try {
                document.execCommand('copy');
                notifyCopiedReceita(true, 2500);
            } catch (err) {
                notifyCopiedReceita(false, 4000);
            } finally {
                tempTextArea.remove();
            }
        }
    }

    const notifyCopiedReceita = (outcome, timer) => {
        setCopyOutcome(outcome);
        setIsCopyNotificationDisplayed(true);
        setTimeout(() => {
            setIsCopyNotificationDisplayed(false);
        }, timer);
    }

    const handleCloseReceita = () => {
        setReceita(false);
        const scrollToList = document.getElementById('scroll-to-list');
        const buttons = document.querySelectorAll('.receitas-button');
        buttons.forEach(button => button.classList.remove('receitas-button-active'));
        handleScroll(scrollToList);
    }

    const handleScroll = (element) => {
        element.scrollIntoView({behavior: 'smooth'})
    }

    return (

        <div>

            <Helmet>
                <title>Frutyl | Receitas</title>
            </Helmet>

            <div className='copy-notification' id='copy-notification'>
                <img src={copyOutcome === true ? copy_successful : 
                          copyOutcome === false ? copy_unsuccessful :
                          ''} alt='' />
                <h2>
                    {
                        copyOutcome === true ? 'Receita copiada!' : 
                        copyOutcome === false ? 'A receita não pôde ser copiada' : 
                        ''
                    }
                </h2>
                <p>
                    {
                        copyOutcome === true ? 'Bom apetite. :)' : 
                        copyOutcome === false ? 'Seu aparelho não suporta essa funcionalidade.' :
                        ''
                    }
                </p>
            </div>

            <MainBanner
                page='receitas'
                pageMainTitle='Receitas culinárias'
                pageSubtitle={`Selecionamos algumas receitas deliciosas que podem ser confeccionadas com a banana.${'\n'}São receitas simples que você pode preparar em casa!`}
                backgroundImage={banner_background_receitas}
                backgroundPosition={[{mobile: '75% top', desktop: 'center 20%'}]}
                additionalInformation={null}
            />

            <div className='main-container'>
                <div className='main-title'>
                    <h1>GASTRONOMIA DA BANANA</h1>
                </div>

                <div className='receitas-content'>

                    <div className='receitas-summary'>
                        <p>A banana é uma fruta muito acessível, fácil de encontrar e fácil de preparar. Isso faz com que ela tenha diversas utilidades na culinária e na gastronomia.</p>
                        <p>Sendo uma ótima opção para a confecção de receitas doces e salgadas, selecionamos algumas receitas que fazem uso dessa fruta incrível e deliciosa.</p>
                        <div className='receitas-summary-image'>
                            <img src={icon_chef} alt='' />
                        </div>
                        <p id='scroll-to-list'>Clique em uma <span>receita</span> para ver como é feita a sua preparação.</p>
                        <div className='receitas-summary-list'>
                            {
                                receitas_data.map(receita => {
                                    return (
                                        <button className='receitas-button' id={receita.receitaId} key={receita.receitaId} onClick={() => handleReceita(receita.receitaId)}>
                                            {receita.receitaShortName}
                                        </button>
                                    )
                                })
                            }
                        </div>
                    </div>
                    
                    <div className='receita' id='receita'>
                        {
                            receita === false ? 
                            <div className='receita-empty'>
                                <p>Selecione uma receita para visualizá-la.</p>
                            </div> 
                            :
                            <div className='receita-content' id='receita-content'>
                                <h2 id='scroll-to-receita'>{receita.receitaFullName}</h2>
                                <div className='receita-image'>
                                    <img src={receita.receitaImage} alt={receita.receitaFullName} />
                                </div>
                                
                                <div className='receita-ingredients' id='receita-ingredients'>
                                    <h3>Ingredientes</h3>
                                    <ul>
                                    {
                                        receita.receitaIngredients.map((ingredient, index) => {
                                            return (
                                                <li key={`INGREDIENT-${index}`}><span></span>{ingredient}</li>
                                            )
                                        })
                                    }
                                    </ul>
                                </div>

                                <div className='receita-cooking' id='receita-cooking'>
                                    <h3>Modo de preparo</h3>
                                    <ol>
                                        {
                                            receita.receitaCooking.map((step, index) => {
                                                return (
                                                    <li key={`STEP-${index + 1}`}><span>{index + 1}.</span> {step}</li>
                                                )
                                            })
                                        }
                                    </ol>
                                </div>

                                <div className='receita-time'>
                                    <h3>Tempo de preparo</h3>
                                    <p>Aproximadamente {receita.receitaTime}.</p>
                                </div>

                                <div className='receita-buttons'>
                                    <p>Se for do seu interesse, você pode <span>copiar</span> ou <span>imprimir</span> essa receita.</p>
                                    <div className='receita-buttons-main'>
                                        <button onClick={() => handleCopyReceita(receita.receitaFullName, receita.receitaTime)}>
                                            <img src={icon_copy} alt='Copiar receita' className='buttons-main-image' />
                                        </button>
                                        <button onClick={printReceita}>
                                            <img src={icon_print} alt='Imprimir receita' className='buttons-main-image' />
                                        </button>
                                    </div>
                                    <div className='receita-buttons-close'>
                                        <button onClick={handleCloseReceita}>fechar visualização</button>
                                    </div>
                                </div>    
                                
                            </div>
                        }
                    </div>
                </div>
            </div>

            <Spotlight
                spotlightLink='/beneficios'
                spotlightTitle='BENEFÍCIOS'
                spotlightDescription={`Gostaria de conhecer os benefícios que a banana proporciona ao seu organismo?${`\n`}Aproveite essa fruta em todo o seu potencial.${`\n\n`}Saiba mais.`}
                spotlightButton='BENEFÍCIOS DA BANANA'
            />
            
        </div>
        
    );
}

export default Receitas;