import React from 'react';
import { GoogleMap, useJsApiLoader, MarkerF } from '@react-google-maps/api';

const Map = ({ latitudeValue, longitudeValue }) => {

    const latitudeAndLongitude = {
        lat: Number(latitudeValue),
        lng: Number(longitudeValue)
    }

    const mapContainerStyles = {
        width: '100%',
        height: '100%'
    }

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
      })

    return !isLoaded ? 'Carregando mapa do local...' : (

        <GoogleMap
            zoom={17}
            center={latitudeAndLongitude}
            mapContainerStyle={mapContainerStyles}
            options={{
                gestureHandling: 'greedy'
            }}
        >
            <MarkerF 
                position={latitudeAndLongitude}
            />
        </GoogleMap>

    )

}

export default Map;