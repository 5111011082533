import React, { useEffect, useState } from 'react';
import './ProdutosSlider.css';

const ProdutosSlider = ({ bananaType, bananaImages }) => {

    const [currentSliderIndex, setCurrentSliderIndex] = useState({
        'caturra': 0,
        'prata': 0
    });

    const goToPreviousImage = bananaType => {
        const isFirstImage = currentSliderIndex[bananaType] === 0;
        const newSliderIndex = isFirstImage ? bananaImages.length - 1 : currentSliderIndex[bananaType] - 1;
        const updateIndex = currentSliderIndex[bananaType] = newSliderIndex;
        setCurrentSliderIndex({...currentSliderIndex, [bananaType]: updateIndex});
    }

    const goToNextImage = bananaType => {
        const isLastImage = currentSliderIndex[bananaType] === bananaImages.length - 1;
        const newSliderIndex = isLastImage ? 0 : currentSliderIndex[bananaType] + 1;
        const updateIndex = currentSliderIndex[bananaType] = newSliderIndex;
        setCurrentSliderIndex({...currentSliderIndex, [bananaType]: updateIndex});
    }

    const goToSpecificImage = (bananaType, imageIndex) => {
        setCurrentSliderIndex({...currentSliderIndex, [bananaType]: imageIndex})
    }

    const sliderImagesStyles = {
        backgroundImage: `url(${bananaImages[currentSliderIndex[bananaType]].url})`
    }

    useEffect(() => {
        let buttons = [];
        const buttonsRaw = document.getElementsByClassName('slider-button');
        const buttonsArray = Array.from(buttonsRaw);
        buttonsArray.forEach(button => {
            if (button.classList[1] === bananaType) {
                buttons.push(button);
            }
        });
        buttons.forEach(button => {
            const buttonId = Number(button.id);
            if (buttonId === currentSliderIndex[bananaType]) {
                button.classList.add('slider-button-active');
            } else {
                button.classList.remove('slider-button-active');
            }
        });
    }, [currentSliderIndex, bananaType]);

    return (

        <div className='produtos-slider'>
            <div className='slider-arrow slider-arrow-left' onClick={() => goToPreviousImage(bananaType)}>&#10094;</div>
            <div className='slider-arrow slider-arrow-right' onClick={() => goToNextImage(bananaType)}>&#10095;</div>
            <div className='slider-images' style={sliderImagesStyles}></div>
            <div className='slider-buttons'>
                {
                    bananaImages.map((image, imageIndex) => (
                        <button className={`slider-button ${bananaType}`} key={imageIndex} id={imageIndex} onClick={() => goToSpecificImage(bananaType, imageIndex)}>&#9679;</button>
                    ))
                }
            </div> 
        </div>

    );

}

export default ProdutosSlider;