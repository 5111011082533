import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

// Images
import footer_logo from '../../assets/images/logo-frutyl.png';

const Footer = ({ windowWidth }) => {

    const footerScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }

    return (

        <div className='footer-container'>

            <div className='footer-wrapper'>

                <div className='footer-menu'>
                    <div className='footer-menu-department'>
                        <h2>Empresa</h2>
                        <ul>
                            <li>
                                <Link to='/'>Home</Link>
                            </li>
                            <li>
                                <Link to='/sobre'>Sobre</Link>
                            </li>
                        </ul>
                    </div>
                    <div className='footer-menu-department'>
                        <h2>Comunidade</h2>
                        <ul>
                            <li>
                                <Link to='/receitas'>Receitas</Link>
                            </li>
                            <li>
                                <Link to='/beneficios'>Benefícios</Link>
                            </li>
                        </ul>
                    </div>
                    <div className='footer-menu-department'>
                        <h2>Comercial</h2>
                        <ul>
                            <li>
                                <Link to='/produtos'>Produtos</Link>
                            </li>
                            <li>
                                <Link to='/servicos'>Serviços</Link>
                            </li>
                            <li>
                                <Link to='/contato'>Contato</Link>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className='footer-logo'>
                    <img src={footer_logo} alt='Frutyl' onClick={footerScrollToTop} />
                </div>

                <div className='footer-contact'>
                    <h2>Contato comercial</h2>
                    {
                        windowWidth < 700 ? 
                        <div>
                            <p>Rua Augusto Rocha, 80. Bairro São Geraldo — Gravataí, Rio Grande do Sul.</p> 
                            <p>Telefone: (51) 3488-3161</p>
                            <p>E-mail: frutyl.cb@gmail.com</p>
                        </div> :
                        <div>
                            <p>Telefone: (51) 3488-3161</p>
                            <p>E-mail: frutyl.cb@gmail.com</p>
                            <p>Rua Augusto Rocha, 80. Bairro São Geraldo.<br/>Gravataí, Rio Grande do Sul.</p>
                        </div>
                    }
                </div>

            </div>

            <div className='footer-copyright'>
                <p>
                    Copyright <span>&copy;</span> {new Date().getFullYear()}. Frutyl — Comércio e Transporte de Bananas.
                </p>
                <p>
                    Todos os direitos reservados.
                </p>
            </div>

        </div>

    );

}

export default Footer;