import React from 'react';
import './Sobre.css';
import { Helmet } from 'react-helmet-async';

// Components
import MainBanner from '../../components/MainBanner/MainBanner';
import Spotlight from '../../components/Spotlight/Spotlight';

// Images
import banner_background_sobre from '../../assets/images/banner-background-sobre.jpg';
import frutyl_1988 from '../../assets/images/frutyl-1988.jpg';
import icon_heart_love from '../../assets/images/icon-heart-love.png';

const Sobre = () => {

    return (

        <div>

            <Helmet>
                <title>Frutyl | Sobre</title>
            </Helmet>

            <MainBanner 
                page='sobre'
                pageMainTitle='Quem somos?'
                pageSubtitle={`Junte-se a nós e explore o passado, presente e futuro da Frutyl.${'\n'}Conheça as nossas origens, instalações e ideologias de negócio.`}
                backgroundImage={banner_background_sobre}
                backgroundPosition={[{mobile: 'center center', desktop: 'center center'}]}
                additionalInformation={null}
            />

            <div className='sobre-main main-container'>
                <div className='main-title'>
                    <h1>CONHEÇA A FRUTYL</h1>
                </div>
                <div className='sobre-content'>
                    <div className='sobre-history'>
                        <p>
                            A história da Frutyl tem na sua origem a coragem e a tenacidade de seu fundador, José Júlio Evaldt. Homem típico do interior, criado na localidade de Morro Azul-RS, região conhecida pela farta produção natural de banana, José Júlio, tratado carinhosamente pelos amigos como "Chuce", forjou sua vida rude de colono na escassez diária de alimentação e qualidade de vida. Adulto, casado e com filhos, e sempre em condições adversas, iniciou uma atividade que conciliava a produção e a comercialização da banana com sua força de vontade e conhecimento de vida.
                        </p>
                        <p>
                            Assim, no ano de 1963, já na cidade de Porto Alegre, nascia a Frutyl Comércio e Transporte de Bananas, uma empresa promissora no ramo de comercialização de bananas; e, para tanto, "Chuce" adquiriu um caminhão Bedford, veículo necessário para transportar a mercadoria e iniciar a empreitada.
                        </p>
                        <p>
                            A tarefa consistia em negociar com os produtores de bananas - conhecidos como “colonos” - ou seja, comprar uma determinada quantidade de bananas e, em seguida, ofertar aos potenciais clientes, tais como: armazéns, minimercados, bares, etc., visando sempre satisfazer as necessidades dos consumidores.
                        </p>
                        <p>
                            Essa tarefa, embora sem complexidade, impunha a "Chuce" uma rotina extremamente desgastante, pois o obrigava a acordar cedo e a dormir tarde, imprimindo um ritmo cada vez mais comprometedor com o mercado no qual estava inserido. Naquela época, transportava-se a banana em cachos e posteriormente era despencada e colocada em balaios para comercialização. Era um trabalho árduo.
                        </p>
                        <p>
                            Os anos passaram e "Chuce" aposentou-se. A empresa passou a ser administrada pelos seus filhos que orgulhosamente mantiveram as atividades, imprimindo uma característica familiar ao negócio. 
                        </p>
                        <p>
                            Assim, aos poucos, a empresa foi consolidando-se entre os produtores e compradores da região, sempre através de muito trabalho e respeito. Hoje em dia, muitos destes compradores tornaram-se parceiros da Frutyl, mantendo uma relação comercial que já vem de décadas.
                        </p>
                        <div className='sobre-image-frutyl'>
                            <img src={frutyl_1988} alt='Frutyl em 1988' />
                            <h3>Vista aérea da Frutyl em 1988</h3>
                        </div>
                        <p>
                            Com sede própria, a empresa encontra-se estabelecida na cidade de Gravataí-RS, contando com uma frota de caminhões e veículos e uma equipe de funcionários altamente especializada em satisfazer a demanda do mercado consumidor.
                        </p>
                        <p>
                            Atualmente, estão à frente da empresa o senhor João Batista Evaldt e seu filho Bruno Evaldt, este representando a terceira geração da família.
                        </p>
                    </div>
                    <div className='sobre-strategy'>
                        <div>
                            <h2>MISSÃO</h2>
                            <p>Estabelecer laços duradouros de confiança com nossos clientes e parceiros, visando manter o alto nível dos nossos produtos e serviços.</p>
                        </div>
                        <div>
                            <h2>VISÃO</h2>
                            <p>Atingir um reconhecimento regional acerca do processo de gerenciamento e manutenção da nossa atividade comercial.</p>
                        </div>
                        <div>
                            <h2>VALORES</h2>
                            <ul>
                                <li>Qualidade</li>
                                <li>Pontualidade</li>
                                <li>Transparência</li>
                                <li>Companheirismo</li>
                                <li>Comprometimento</li>
                            </ul>
                        </div>
                    </div>
                    <div className='sobre-image-heart'>
                        <img src={icon_heart_love} alt='' />
                    </div>
                </div>
            </div>

            <Spotlight 
                spotlightLink='/produtos'
                spotlightTitle='PRODUTOS'
                spotlightDescription={`Quer conhecer mais sobre os produtos que comercializamos?${'\n'}Disponibilizamos uma breve descrição sobre alguns processos de produção e logística dos nossos produtos.${`\n\n`}Venha saber mais.`}
                spotlightButton='PRODUTOS DISPONÍVEIS'
            />

        </div>
    );
}

export default Sobre;